/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function($) {

		// Use this variable to set up the common and page specific functions. If you
		// rename this variable, you will also need to rename the namespace below.
		var Sage = {
				// All pages
				'common': {
						init: function() {
								var timeout;

								if(!$('body').hasClass('home')){
									$('.headroom').headroom();

									$(window).on('load mousemove', function() {
											$('.site-header').removeClass('inactive');
											clearTimeout(timeout);
											timeout = setTimeout(function () {
												$('.site-header').addClass('inactive');
											}, 4000);

									});									
								}

						},
						finalize: function() {}
				},
				// Home page
				'home': {
						init: function() {

								$('.hero__slider').slick({
										infinite: true,
										autoplay: true,
										autoplaySpeed: 3000
								});

						},
						finalize: function() {
								// JavaScript to be fired on the home page, after the init JS
						}
				},
				// About us page, note the change from about-us to about_us.
				'page_template_template_about': {
						init: function() {

						},
						finalize: function() {


								// -------------------------------
								// Filter Config

								var filter = filter || {};

								filter.config = {
										animation: {
												enable: false
										},
										controls: {
												toggleLogic: 'and',
												toggleFilterButtons: true
										},
										selectors: {
												target: '.client__box'
										}
								};

								var $container = $('.client__filter--result');
								var $filterSelect = $('.partner-filter');

								// -------------------------------
								// Filter init

								$container.mixItUp(filter.config);

								// -------------------------------
								// Filter

								$filterSelect.on('click touch', function() {

										$filterSelect.removeClass('active');
										$(this).addClass('active');

										var val = $(this).data('filter');
										if (val === 'all') {
												$container.mixItUp('filter', 'all');
										} else {
												$container.mixItUp('filter', '.' + val);
										}
								});


								// -------------------------------
								// Timeline

								var $timeLine = $('.timeline');
								var $event = $('.timeline__event');
								var $year = $('.timeline__year');
								var $timeLineWrapper = $('.timeline__data');
								// var $pass = $('.timelime__control--pass');
								// var $future = $('.timelime__control--future');

								var dayWidth = 0.3;
								var oneDay = 1000 * 60 * 60 * 24;


								function parseDate(date) {
										var input = date.split('/');
										return new Date(input[2], input[0], input[1]);
								}

								function dayDiff(begin, end) {
										return Math.round((parseDate(end) - parseDate(begin)) / oneDay);
								}

								var begin = $event.first().attr('data-date');
								var end = $event.last().attr('data-date');
								var totalWidth = dayDiff(begin, end) * dayWidth;


								function checkTitle(distance, el) {
										if (distance > totalWidth - $timeLineWrapper.width() / 2) {
												el.addClass('reverse');
												el.find('.timeline__dot--title').css({
														left: el.width() * -1 + 25
												});
										}
								}

								// Timeline Init -----

								$event.each(function(index) {
										var date = $(this).attr('data-date');
										var distance = dayDiff(begin, date) * dayWidth;
										$(this).css({
												left: distance
										});

										checkTitle(distance, $(this));

										if (index % 2 !== 0) {
												$(this).addClass('even');
										}

								});

								$year.each(function(index) {
										var date = '01/01/' + $(this).text();
										var distance = dayDiff(begin, date);
										$(this).css({
												left: distance * dayWidth
										});
								});

								// Timeline Control -----

								// $future.on('click', function(){
								//   $timeLineWrapper.animate({scrollLeft: $timeLineWrapper.scrollLeft() + $timeLineWrapper.width()}, 500);
								// });

								// $pass.on('click', function(){
								//   $timeLineWrapper.animate({scrollLeft: $timeLineWrapper.scrollLeft() - $timeLineWrapper.width() }, 500);
								// });



								// Slick Slider --------------------------


								function setActive() {
										var currentDate = $('.slick-current').attr('data-date');
										var $current = $('.timeline__event[data-date="' + currentDate + '"]');
										$('.timeline__event').removeClass('active');
										$current.addClass('active');
								}

								function scrollActiveToVisible() {
										var currentDate = $('.slick-current').attr('data-date');
										var $current = $('.timeline__event[data-date="' + currentDate + '"]');

										var wrapperMid = $timeLineWrapper.scrollLeft() + $timeLineWrapper.width() / 2;
										var currentPos = $current.css('left').replace(/[^-\d\.]/g, '');

										scroll = 0;
										if (currentPos !== wrapperMid) {
												scroll = currentPos - $timeLineWrapper.width() / 2;
										}
										$timeLineWrapper.animate({
												scrollLeft: scroll
										}, 500);
								}


								var $slick = $('.history__detail');

								$slick.slick({
										adaptiveHeight: true,
										infinite: true,
										slidesToShow: 1,
										arrows: true
								}).on('init swipe afterChange', function(event, slick, currentSlide, nextSlide) {
										setActive();
										scrollActiveToVisible();
								});

								setActive();


								$event.on('click', function() {
										var date = $(this).attr('data-date');
										var slideIndex = $('.history__detail--slide[data-date="' + date + '"]').attr('data-slick-index');
										$slick.slick('slickGoTo', slideIndex);
										setActive();
								});




						}
				},
				// Project Single Page
				'single_projects': {
						init: function() {

								var $sliderMain = $('.single__gallery--main');
								var $sliderNav = $('.single__gallery--nav');

								$sliderMain.slick({
										infinite: false,
										asNavFor: '.single__gallery--nav',
										slidesToScroll: 1,
										adaptiveHeight: true,
										arrows: false
								});

								$sliderNav.slick({
										dots: true,
										asNavFor: '.single__gallery--main',
										infinite: false,
										speed: 300,
										slidesToShow: 3,
										slidesToScroll: 1,
										variableWidth: true,
										arrows: false
								});


								$sliderNav.find('.slick-slide').on('click', function (e) {
									var i = $(e.target).data('slick-index');
									$sliderMain.slick('slickGoTo', i);

								});

						}
				},
				// Project Single Page
				'single_cases': {
						init: function() {

								$('.single__gallery').slick({
										infinite: true,
										adaptiveHeight: true
								});


						}
				},
				// Project & Cases Filter
				'projects_cases': {
						init: function() {},
						finalize: function() {

								// -------------------------------
								// Filter Config

								var filter = filter || {};

								filter.config = {
										animation: {
												enable: false
										},
										controls: {
												toggleLogic: 'and',
												toggleFilterButtons: true
										},
										selectors: {
												target: '.result__box'
										}
								};

								filter.reset = function($container, $filterSelect) {
										$container.mixItUp('filter', 'all');
										$filterSelect.find('option:first').attr('selected', 'selected');
								};

								// -------------------------------
								// Filter related selector 

								var $filterSelect = $('.form__filter select');
								var $sortSelect = $('.form__sort select');
								var $container = $('.project__result--content');
								var $reset = $('.project__filter--reset');
								var $tabLink = $('.project__tab-link');

								// -------------------------------
								// Init Filter 

								$container.mixItUp(filter.config);

								// -------------------------------
								// Filter

								$filterSelect.change(function(e) {
										$('.project__result--content.active').mixItUp('filter', '.' + $(this).val());
								});


								// -------------------------------
								// Sort 

								$sortSelect.change(function(e) {
										$('.project__result--content.active').mixItUp('sort', $(this).val());
								});


								// -------------------------------
								// Tab 


								$tabLink.on('click touch', function(e) {
										e.preventDefault();
										var tabType = $(this).data('tab');

										$reset.hide();

										$("*[data-tab]").removeClass('active');
										$("*[data-tab='" + tabType + "'").addClass('active');
										$container = $(".project__result--content[data-tab='" + tabType + "']");

										filter.reset($container, $filterSelect);

										if ($container.data('default-sort')) {
												$container.mixItUp('sort', $container.data('default-sort'));
										}
								});


								// -------------------------------
								// Reset filter

								$container.on('mixEnd', function(e, state) {
										if (state.totalHide > 0) {
												$reset.show();
										} else {
												$reset.hide();
										}
								});

								$reset.on('click', function(e) {
										e.preventDefault();
										filter.reset($container, $filterSelect);
								});
						}

				}

		};

		// The routing fires all common scripts, followed by the page specific scripts.
		// Add additional events for more control over timing e.g. a finalize event
		var UTIL = {
				fire: function(func, funcname, args) {
						var fire;
						var namespace = Sage;
						funcname = (funcname === undefined) ? 'init' : funcname;
						fire = func !== '';
						fire = fire && namespace[func];
						fire = fire && typeof namespace[func][funcname] === 'function';

						if (fire) {
								namespace[func][funcname](args);
						}
				},
				loadEvents: function() {
						// Fire common init JS
						UTIL.fire('common');

						// Fire page-specific init JS, and then finalize JS
						$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
								UTIL.fire(classnm);
								UTIL.fire(classnm, 'finalize');
						});

						// Fire common finalize JS
						UTIL.fire('common', 'finalize');
				}
		};

		// Load Events
		$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.